import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import SwiperCore from 'swiper';
import { Autoplay, A11y } from 'swiper';
import { SwiperSlide, Swiper } from 'swiper/react';

import styles from './HomepageCarousel.css';
import { MiscUtils } from '../../../utils/MiscUtils';
import { HOMEPAGE_CAROUSEL_DELAY } from '../../constants/HomepageCarousel';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { PauseIcon } from '../../FigmaStyleguide/Icons/PauseIcon';
import { PlayIcon } from '../../FigmaStyleguide/Icons/PlayIcon';
import { Analytics } from '../../services/Analytics/Analytics';
import { LeanplumAnalytics, LEANPLUM_EVENTS } from '../../services/Analytics/LeanplumAnalytics';
import { LeanplumCarouselSlide } from '../../store/ducks/leanplum/homepageCarousel';

export const HomepageCarousel = () => {
    const items = useSelector((state) => state.leanplumCarouselData);
    const [sliderPlaying, setSliderPlaying] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [device, setDevice] = useState<ReturnType<typeof getDevice>>(getDevice());

    function getDevice() {
        if (MiscUtils.isServer) {
            return 'desktop';
        }
        const isMobile = (window as any).matchMedia('(max-width: 576px)').matches;
        if (isMobile) {
            return 'mobile';
        }
        const isTablet = (window as any).matchMedia('(max-width: 1024px)').matches;
        if (isTablet) {
            return 'tablet';
        }
        return 'desktop';
    }

    const getBackgroundImage = useCallback(
        (item: LeanplumCarouselSlide) => {
            let bg = item.desktopBackgroundImage || item.backgroundImage;
            switch (device) {
                case 'mobile':
                    bg =
                        item.mobileBackgroundImage ||
                        item.tabletBackgroundImage ||
                        item.desktopBackgroundImage ||
                        item.backgroundImage;
                    break;
                case 'tablet':
                    bg = item.tabletBackgroundImage || item.desktopBackgroundImage || item.backgroundImage;
                    break;
                case 'desktop':
                default:
                    break;
            }
            return bg ? `url(${bg})` : undefined;
        },
        [device]
    );

    const getSlideContent = useCallback(
        (item: LeanplumCarouselSlide, field: string) => {
            //get field by device
            let value = '';
            switch (device) {
                case 'mobile':
                    value = getTextSlide(item, field, 'mobile');
                    break;
                case 'tablet':
                case 'desktop':
                    value = getTextSlide(item, field, 'desktop');
                default:
                    break;
            }
            return value;
        },
        [device]
    );

    const getTextSlide = (item: LeanplumCarouselSlide, field: string, device: string) => {
        let value = '';
        switch (field) {
            case 'title':
                device == 'mobile' && item?.mobileContent === true ? (value = item.mobileTitle) : (value = item.title);
                break;
            case 'subtitle':
                device == 'mobile' && item?.mobileContent === true
                    ? (value = item.mobileSubtitle)
                    : (value = item.subtitle);
                break;
        }
        return value;
    };

    const swiperRef = useRef<SwiperCore>();

    const pauseHandler = () => {
        swiperRef.current.autoplay.running ? swiperRef.current.autoplay.stop() : swiperRef.current.autoplay.start();
        setSliderPlaying(swiperRef.current.autoplay.running);
    };

    const onSlideButtonClick = ({ buttonText, buttonLink }: LeanplumCarouselSlide, i: number) => {
        Analytics.trackEvent(Analytics.general.carouselButton(i.toString(), buttonText));
        LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.CAROUSEL_BUTTON, { slot: i.toString(), buttonLabel: buttonText });
        setTimeout(() => (window.location.href = buttonLink), 300);
    };

    useEffect(() => {
        const updateDevice = () => {
            setDevice(getDevice());
        };
        const debounceEvent = debounce(updateDevice, 200);

        window.addEventListener('resize', debounceEvent);

        return () => window.removeEventListener('resize', debounceEvent);
    }, []);

    return (
        <>
            <Helmet>
                {items.map((item) => (
                    <link
                        key={item.title}
                        rel="preload"
                        as="image"
                        href={
                            getBackgroundImage(item)
                                ? getBackgroundImage(item).replace('url(', '').replace(')', '')
                                : ''
                        }
                    />
                ))}
            </Helmet>
            <Swiper
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                }}
                slidesPerView={1}
                onActiveIndexChange={(swiper) => {
                    setActiveIndex(swiper.activeIndex);
                }}
                modules={[Autoplay, A11y]}
                autoplay={{ delay: HOMEPAGE_CAROUSEL_DELAY, disableOnInteraction: true }}
                a11y={{ enabled: true }}
                className={classNames(styles.slide)}
                onAutoplayStart={() => setSliderPlaying(true)}
                onAutoplayStop={() => setSliderPlaying(false)}
            >
                {items.map((item, i) => {
                    return (
                        <SwiperSlide
                            /* eslint-disable-next-line */
                            key={'swiper-slide-' + i}
                            // added for accessibility: slider didn't work properly with tabs without this function
                            onFocus={() => {
                                if (!swiperRef?.current) {
                                    return;
                                }
                                /* eslint-disable-next-line */
                                swiperRef.current.el.scrollLeft = 0;
                            }}
                        >
                            <div
                                className={styles.slide}
                                style={{
                                    backgroundImage: getBackgroundImage(item),
                                }}
                            >
                                <div
                                    className={classNames(styles.content, {
                                        [styles.mobile]:
                                            device === 'mobile' &&
                                            item?.mobileTitle === '' &&
                                            item?.mobileSubtitle === '',
                                    })}
                                >
                                    <h1>{getSlideContent(item, 'title')}</h1>
                                    <h2>{getSlideContent(item, 'subtitle')}</h2>
                                    <Button onClick={() => onSlideButtonClick(item, i)} className={styles.whiteBtn}>
                                        {item.buttonText}
                                    </Button>
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })}
                <div className={styles.pagination}>
                    <button
                        type="button"
                        onClick={pauseHandler}
                        className={styles.handlerBtn}
                        aria-label={sliderPlaying ? 'Pause slider' : 'Autoplay slider'}
                    >
                        {sliderPlaying ? <PauseIcon /> : <PlayIcon />}
                    </button>
                    {items.map((_, i) => (
                        <button
                            /* eslint-disable-next-line */
                            key={'pagination-item-' + i}
                            type="button"
                            className={classNames(styles.pageButton, {
                                [styles.active]: i === activeIndex,
                            })}
                            onClick={() => {
                                if (i !== activeIndex) {
                                    swiperRef.current.slideTo(i);
                                }
                            }}
                            aria-label={`Go to slide number ${i + 1}`}
                        />
                    ))}
                </div>
            </Swiper>
        </>
    );
};
