import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { DeviceUtils } from '../../../utils/DeviceUtils';
import { ButtonWithIcon, ButtonWithIconTypes } from '../../atoms/Buttons/ButtonWithIcon/ButtonWithIcon';
import { I18nText } from '../../atoms/i18nText/i18nText';
import styles from './PromoCard.css';

interface PromoCardProps extends RouteComponentProps {
    image: string;
    imageWebp: string;
    caption?: string;
    description: string;
    buttonCaption: string;
    url: string;
    onButtonClick?: React.MouseEventHandler<HTMLDivElement>;
    promoCardClassName?: string;
}

export const PromoCard = withRouter(
    React.memo(
        ({
            image,
            // TODO: make decision about usage of this prop
            // imageWebp,
            caption,
            description,
            buttonCaption,
            url,
            onButtonClick,
            promoCardClassName,
            history,
        }: PromoCardProps) => {
            const [loading, setLoading] = useState(true);

            const backgroundImageStyle = image && image !== '' ? { backgroundImage: `url(${image})` } : {};

            const ref = useRef<HTMLDivElement>(null);

            useEffect(() => {
                if (loading) {
                    const observer = new IntersectionObserver(
                        ([entry]) => {
                            if (entry.isIntersecting) {
                                setLoading(false);
                            }
                        },
                        {
                            root: null,
                            rootMargin: '100px',
                            threshold: 0.1,
                        }
                    );
                    if (ref.current) {
                        observer.observe(ref.current);
                    }
                }
            }, [ref]);

            return (
                <div
                    className={styles.buttonWrapper}
                    onKeyDown={() => {
                        if (DeviceUtils.isStyleguideMobileMode()) {
                            history.push(url);
                        }
                    }}
                    ref={ref}
                    role="button"
                    onClick={(e) => {
                        onButtonClick?.(e);
                        history.push(url);
                    }}
                >
                    <div className={styles.container}>
                        <div className={styles.info}>
                            {caption && <I18nText as="p" className={styles.caption} keyName={caption} />}

                            <I18nText
                                as="p"
                                className={classNames(styles.description, {
                                    [styles.withoutCaption]: !caption,
                                })}
                                keyName={description}
                            />
                            <div className={classNames(styles.buttonContainer, promoCardClassName)}>
                                <ButtonWithIcon
                                    buttonType={ButtonWithIconTypes.TRANSPARENT}
                                    caption={buttonCaption}
                                />
                            </div>
                        </div>
                        <div className={styles.imageWrapper}>
                            <div className={styles.image} style={!loading ? backgroundImageStyle : {}} />
                        </div>
                    </div>
                </div>
            );
        }
    )
);
