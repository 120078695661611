import React from 'react';

import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import styles from './AdDividerPromoCard.css';
import { ButtonWithIcon, ButtonWithIconTypes } from '../../../atoms/Buttons/ButtonWithIcon/ButtonWithIcon';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { ImageTypes, LazyImage } from '../../../atoms/ProgressiveImage/LazyImage';

interface AdDividerPromoCardProps extends RouteComponentProps {
    caption: string;
    captionTag?: string;
    imagePath: string;
    description?: string;
    buttonCaption: string;
    url: string;
    adDividerClassName?: string;
    buttonDisabled?: boolean;
    newWindow?: boolean;
    onClick?: (e: any) => void;
    renderDescription?: () => any;
}

export const AdDividerPromoCard = withRouter(
    React.memo((props: AdDividerPromoCardProps) => {
        const buttonWithIcon = (
            <ButtonWithIcon
                caption={props.buttonCaption}
                buttonType={ButtonWithIconTypes.SECONDARY}
                dataElementDescription="ad-divider-button"
            />
        );

        const CaptionTag = (props.captionTag ? props.captionTag : 'h2') as any;

        const imagePath = props.imagePath.split('.').slice(0, -1).join('.');
        const imageWebp = `${imagePath}.webp`;
        const imagePng = `${imagePath}.png`;

        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                handleClick(e);
            }
        };

        const handleClick = (e) => {
            if (props.onClick) {
                props.onClick(e);
            }
            if (props.newWindow) {
                window.open(props.url, '_blank');
            } else {
                props.history.push(props.url);
            }
        };
        // TODO review to use button instead of div
        return (
            <div
                role="button"
                className={classNames(styles.container, props.adDividerClassName)}
                onClick={handleClick}
                onKeyDown={handleKeyPress}
                tabIndex={0}
                aria-label="Button Label"
            >
                <div className={styles.content}>
                    <LazyImage
                        imageType={ImageTypes.SQUARE}
                        img={imagePng}
                        webp={imageWebp}
                        className={styles.image}
                        alt={props.buttonCaption}
                        aria-hidden="true"
                    />
                    <I18nText as={CaptionTag} className={styles.caption} keyName={props.caption} />

                    {props.renderDescription ? (
                        <p className={styles.description}>{props.renderDescription()}</p>
                    ) : (
                        <I18nText as="p" className={styles.description} keyName={props.description} />
                    )}

                    {props.buttonDisabled ? '' : buttonWithIcon}
                </div>
            </div>
        );
    })
);
