import React from 'react';

import classNames from 'classnames';

import styles from './SeoCard.css';
import { ButtonWithIcon, ButtonWithIconTypes } from '../../../atoms/Buttons/ButtonWithIcon/ButtonWithIcon';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { ImageTypes, LazyImage } from '../../../atoms/ProgressiveImage/LazyImage';
import { DeviceType } from '../../../services/DeviceDetector';
import { reduxStore } from '../../../store';

export enum SeoCardIllustration {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
    THIRD = 'THIRD',
}

const { store } = reduxStore;

const IllustrationsWebMap = new Map<SeoCardIllustration, string>();
IllustrationsWebMap.set(SeoCardIllustration.FIRST, '/illustrations/seo/illustrations-web-seo-card-3.png');
IllustrationsWebMap.set(SeoCardIllustration.SECOND, '/illustrations/seo/illustrations-web-seo-card-2.png');
IllustrationsWebMap.set(SeoCardIllustration.THIRD, '/illustrations/seo/illustrations-web-seo-card-1.png');

const IllustrationsMobileMap = new Map<SeoCardIllustration, string>();
IllustrationsMobileMap.set(SeoCardIllustration.FIRST, '/illustrations/seo/illustrations-mobile-seo-card-3.png');
IllustrationsMobileMap.set(SeoCardIllustration.SECOND, '/illustrations/seo/illustrations-mobile-seo-card-2.png');
IllustrationsMobileMap.set(SeoCardIllustration.THIRD, '/illustrations/seo/illustrations-mobile-seo-card-1.png');

type SeoCardProps = {
    buttonType?: ButtonWithIconTypes;
    illustrationType?: SeoCardIllustration;
    image: string;
    text: string;
    buttonCaption: string;
    onClick: () => void;
};

export const SeoCard = React.memo(
    ({
        buttonType = ButtonWithIconTypes.SECONDARY,
        illustrationType,
        image,
        text,
        buttonCaption,
        onClick,
    }: SeoCardProps) => {
        // const imagePath = IllustrationsWebMap.get(illustrationType).split('.').slice(0, -1).join('.');
        // const imagePath = image.split('.').slice(0, -1).join('.');

        // hardcoding stuff for french page
        let imagePath;
        if (image == 'FIRST' || image == 'SECOND' || image == 'THIRD') {
            imagePath = IllustrationsWebMap.get(illustrationType).split('.').slice(0, -1).join('.');
        } else {
            imagePath = image.split('.').slice(0, -1).join('.');
        }

        const handleKeyPress = (onClick) => (event) => {
            if (event.key === 'Enter') {
                onClick();
            }
        };
        // TODO review to use button instead of div
        return (
            <div
                role="group"
                aria-label="Group item"
                className={styles.seoCardContainer}
                data-element-description="seo-card"
            >
                <div
                    role="button"
                    onClick={onClick}
                    className={styles.seoCard}
                    onKeyDown={handleKeyPress(onClick)}
                    tabIndex={0}
                    aria-label="Button Label"
                >
                    <div className={classNames(styles.header, styles[illustrationType])}>
                        {store.getState().deviceType !== DeviceType.DESKTOP && (
                            <LazyImage
                                imageType={ImageTypes.SQUARE}
                                img={`${imagePath}.png`}
                                webp={`${imagePath}.webp`}
                                className={styles.headerImg}
                                alt={buttonCaption}
                                aria-hidden="true"
                                key="imageweb"
                            />
                        )}
                        {store.getState().deviceType === DeviceType.DESKTOP && (
                            <div style={{ position: 'relative', width: '100%' }}>
                                <LazyImage
                                    imageType={ImageTypes.SQUARE}
                                    img={`${imagePath}.png`}
                                    webp={`${imagePath}.webp`}
                                    className={styles.headerImg}
                                    alt={buttonCaption}
                                    key="imagemobile"
                                />
                            </div>
                        )}
                    </div>
                    <I18nText as="div" html className={styles.content} keyName={text} />
                </div>
                <div className={styles.buttonContainer}>
                    <ButtonWithIcon
                        className={styles.button}
                        captionClassName={styles.buttonCaption}
                        buttonType={buttonType}
                        caption={buttonCaption}
                        wrapLines
                        onClick={onClick}
                    />
                </div>
            </div>
        );
    }
);
